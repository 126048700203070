const STATUS_CONFIG = {
  STATUS_STOP: -3,
  STATUS_EXPIRED: -2,
  STATUS_FALSE: -1,
  STATUS_PENDING: 0,
  STATUS_PROCESS: 1,
  STATUS_SUCCESS: 2,
};

export default {
  STATUS_CONFIG: STATUS_CONFIG,
};
