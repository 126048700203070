<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Config chi phí</h6>
        </template>
        <b-row>
          <b-col>
            <b-form-group label="Chọn loại phí">
              <b-form-select v-model="input.type" :options="options.feeType">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Nhóm đối tượng thu/trả phí">
              <b-form-select
                v-model="input.entity"
                :options="options.groupObject"
                value-field="code"
                text-field="name"
              >
                <b-form-select-option :value="null"
                  >Tất cả
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Đối tượng thu/trả">
              <b-form-select
                v-model="input.entity_detail"
                :options="options.object"
                value-field="code"
                text-field="name"
              >
                <b-form-select-option :value="null"
                  >Tất cả
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Loại dịch vụ">
              <b-form-select
                v-model="input.action"
                :options="options.action"
                value-field="code"
                text-field="name"
              >
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Trạng thái">
              <b-form-select
                v-model="input.status"
                :options="options.active"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Hiệu lực từ ngày">
              <b-form-input v-model="input.from" :type="`date`"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Đến ngày">
              <b-form-input v-model="input.to" :type="`date`"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button class="mr-2" variant="outline-primary" @click="getList(1)"
              >Tìm kiếm
            </b-button>
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="linkCreate()"
              >Thêm mới
            </b-button>
          </b-col>
        </b-row>

        <hr />
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template v-slot:cell(entity)="data">
            <span>Nhóm: {{ data.item.entity }}</span>
            <br>
            <span>Đối tượng: {{ data.item.entity_detail }}</span>
          </template>

          <template v-slot:cell(services)="data">
            <span v-if="data.item.services">DV: {{ data.item.services }}</span>
            <br>
            <span v-if="data.item.products">SP: {{ data.item.products }}</span>
          </template>

          <template v-slot:cell(start_date)="data">
            <span>{{ data.item.start_date }}</span>
            <br>
            <span>{{ data.item.end_date }}</span>
          </template>

          <template v-slot:cell(status)="data">
            <b-badge v-if="data.item.status === STATUS_EXPIRED" variant="danger"
              >Hết hạn
            </b-badge>
            <b-badge v-if="data.item.status === STATUS_FALSE" variant="danger"
              >Từ chối
            </b-badge>
            <b-badge
              v-if="data.item.status === STATUS_PENDING"
              variant="warning"
              >Chờ phê duyệt
            </b-badge>
            <b-badge
              v-if="data.item.status === STATUS_PROCESS"
              variant="success"
              >Đang hoạt động
            </b-badge>
            <b-badge v-if="data.item.status === STATUS_STOP" variant="secondary"
              >Tạm dừng
            </b-badge>
          </template>
          <template v-slot:cell(option)="data">
            <b-button-group>
              <b-button variant="primary" size="sm">
                <router-link target="_blank" class="text-white" :to="{ path: '/tools/fee/view?id=' + data.item.id }">
                  Chi tiết
                </router-link>
              </b-button>

              <b-button
                  variant="success"
                  v-if="data.item.status === STATUS_PENDING"
                  @click="accept(data.item.id, 1)"
                  size="sm">Duyệt
              </b-button>
              <b-button
                  v-if="data.item.status === STATUS_PENDING"
                  variant="danger"
                  @click="accept(data.item.id, -1)"
                  size="sm">
                Từ chối
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import defineConfig from "@/core/config/define.config";

Vue.component("multiselect", Multiselect);
const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      STATUS_EXPIRED: defineConfig.STATUS_CONFIG.STATUS_EXPIRED,
      STATUS_FALSE: defineConfig.STATUS_CONFIG.STATUS_FALSE,
      STATUS_SUCCESS: defineConfig.STATUS_CONFIG.STATUS_SUCCESS,
      STATUS_PENDING: defineConfig.STATUS_CONFIG.STATUS_PENDING,
      STATUS_PROCESS: defineConfig.STATUS_CONFIG.STATUS_PROCESS,
      STATUS_STOP: defineConfig.STATUS_CONFIG.STATUS_STOP,
      isset: {
        listProduct: false,
        service: false,
      },
      query: null,
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      fields: [
        { id: "Id" },
        { type: "Loại phí" },
        { action: "Loại dịch vụ" },
        { entity: "Nhóm đối tượng thu/ trả" },
        // { entity_detail: "Đối tượng thu/ trả" },
        // { cycle: "Phương thức tính phí" },
        { services: "Dịch vụ/Sản phẩm" },
        // { vat: "Vat" },
        { start_date: "Thời gian" },
        // { end_date: "Đến ngày" },
        { status: "Trạng thái" },
        { option: "Hành động" },
      ],
      input: {
        type: null,
        status: null,
        cycle: null,
        feeType: "COLLECT",
        object: null,
        transactionType: null,
        provider: null,
        serviceType: null,
        service: null,
        product: null,
        entity: null,
        listProduct: null,
        chargeFee: "GMV",
        feeOfGMVOn: "DATE",
        entity_detail: null,
        action: null,
        fee: {
          structure: "FLAT",
          charge: null,
          gmvOn: null,
          progressive: null,
          dataSetting: [
            {
              min: null,
              max: null,
              value: null,
            },
          ],
          value: null,
          unit: "PERCENT",
          plus: null,
          type: null,
          cycle: null,
        },
        free: {
          isset: null,
          data: {
            numberTransaction: null,
            gmv: null,
            condition: null,
          },
        },
        minimumValue: null,
        maintainValue: null,
        issetVat: null,
        vat: null,
        from: null,
        to: null,
        priority: null,
        active: null,
        listAction: null,
      },
      options: {
        groupObject: [],
        feeType: [
          { value: null, text: "Tất cả" },
          { value: "RECEIVED", text: "Phí thu" },
          { value: "EXPENSE", text: "Phí Trả" },
        ],
        object: [
          // { code: "COLLECT_AND_PAY", name: "Thu-Chi hộ" },
          // { code: "PAYGATE", name: "Cổng" },
          // { code: "PARTNER", name: "Đối tác" },
          // { code: "CUSTOMER", name: "Khách hàng" },
          // { code: "SERVICE_PROVIDER", name: "NCC dịch vụ" },
        ],
        cycle: [
          { value: null, text: "Tất cả" },
          { value: "FLAT", text: "Phẳng" },
          { value: "LADDER", text: "Bậc thang" },
        ],
        transactionType: [],
        provider: [],
        serviceType: [],
        service: [],
        product: [],
        chargeFee: [
          { value: "VALUE_TRANSACTION", text: "Giá trị từng giao dịch" },
          { value: "GMV", text: "Tổng GMV" },
          { value: "PRICE", text: "Mệnh giá" },
        ],
        feeOfGMVOn: [
          { value: "DATE", text: "Cuối ngày" },
          { value: "WEEK", text: "Cuối tuần" },
          { value: "MONTH", text: "Cuối tháng" },
          { value: "QUARTER", text: "Cuối quý" },
          { value: "YEAR", text: "Cuối năm" },
        ],
        fee: {
          structure: [
            { value: "FLAT", text: "Phẳng" },
            { value: "LADDER", text: "Bậc thang" },
          ],
          progressive: [
            { value: "GMV", text: "GMV" },
            { value: "TRANSACTION", text: "Số lượng giao dịch" },
          ],
          gmvOn: [],
          unit: [
            { value: "PERCENT", text: "Phần trăm" },
            { value: "FIXED", text: "Cố định" },
          ],
        },
        free: {
          data: {
            condition: [
              { value: "AND", text: "AND" },
              { value: "OR", text: "OR" },
            ],
          },
        },
        active: [
          { value: null, text: "Tất cả" },
          { value: -2, text: "Hết hạn" },
          { value: -1, text: "Từ chối" },
          { value: -3, text: "Tạm dừng" },
          { value: 0, text: "Chờ phê duyệt" },
          { value: 1, text: "Đang hoạt động" },
        ],
        action: [],
        priority: [],
        listAction: [],
      },
      listAllConfigFee: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Config chi phí", route: "fee" },
    ]);
  },
  methods: {
    accept(id, status) {
      let params = {
        id: id,
        status: status,
      };
      this.$bus.$emit("show-loading", true);
      CmsRepository.acceptFee(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.notifyAlert("success", response.data.message);
          }
          this.getList();
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
          this.$bus.$emit("show-loading", false);
        });
    },
    linkCreate() {
      return this.$router.push({ name: "fee-create" });
    },
    linkDetail(id) {
      this.$router.push("/tools/fee/view?id=" + id);
      // return this.$router.push({ name: "fee-view", params: { id: id } });
    },
    convert(params) {
      for (let key in params) {
        if (params[key] == "") {
          delete params[key];
        }
      }
      return params;
    },
    listConfigService() {
      this.$bus.$emit("show-loading", true);
      let params = {};
      CmsRepository.listServiceConfigFee(params)
        .then((response) => {
          if (!response.data.data.error_code) {
            this.listAllConfigFee = response.data.data;
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
    getList(page = 1) {
      this.items = [];
      let params = { ...this.input };
      params = this.convert(params);
      params.page = page;
      this.$bus.$emit("show-loading", true);
      params.status = this.input.status;
      CmsRepository.listAllFee(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.items = response.data.data.data;
            this.paginate.total = response.data.data.total;
            this.paginate.totalPage = response.data.data.last_page;
            this.paginate.currentPage = response.data.data.current_page;
            // this.notifyAlert("success", response.data.message);
            this.$bus.$emit("show-loading", false);
          }
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    setObjectOption() {
      this.options.groupObject = [];
      this.options.groupObject = this.listAllConfigFee[this.input.type];
    },
    setTransactionOrService() {
      this.options.object = [];

      this.listAllConfigFee[this.input.type] = this.formatArrayKeyValue(
        this.listAllConfigFee[this.input.type]
      );

      if (this.input.entity == "SERVICE_PROVIDER") {
        if (typeof this.listAllConfigFee[this.input.type][this.input.entity].data !== "undefined") {
          this.listAllConfigFee[this.input.type] = this.formatArrayKeyValue(this.listAllConfigFee[this.input.type]);
          this.options.object = this.listAllConfigFee[this.input.type][this.input.entity].data;
        }
      } else {
        if (
          typeof this.listAllConfigFee[this.input.type][this.input.entity]
            .data !== "undefined"
        ) {
          this.options.object =
            this.listAllConfigFee[this.input.type][this.input.entity].data;
        }
      }
    },
    setAction() {
      this.options.action = [];

      if (typeof this.listAllConfigFee[this.input.type][this.input.entity].data !== "undefined") {
        this.listAllConfigFee[this.input.type] = this.formatArrayKeyValue(this.listAllConfigFee[this.input.type]);
        let objects = this.formatArrayKeyValue(this.listAllConfigFee[this.input.type][this.input.entity].data);
        this.options.action = objects[this.input.entity_detail] !== "undefined" ? objects[this.input.entity_detail].data : [];
      }
    }
  },

  created() {
    this.$bus.$emit("show-loading", true);
    this.query = this.$route.query;
    this.getList();
    this.listConfigService();
    this.$bus.$emit("show-loading", false);
  },
  watch: {
    "input.type"() {
      this.input.entity = null;
      this.setObjectOption();
    },
    "input.entity"() {
      this.input.entity_detail = null;
      this.setTransactionOrService();
    },
    "input.entity_detail"() {
      this.input.action = null;
      this.setAction();
    },
    currentPage() {
      this.getList(this.currentPage);
    },
  },
};
</script>
